import React, { useEffect,useState } from 'react';
import { FaTwitter } from 'react-icons/fa';
import { ApprovaledlListLayout, ApprovaledlListBody, GREENTitle, ApprovaledlListRow, ApprovaledlListRowKu,
    ApprovaledlListRowOfHead,ButtonContainer } from './ApprovaledList.style.js';
import FW from '../../config/word.json';
import {RED,} from '../../styles/color';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../components/Layouts/Main/MainLayout.index.js';
import {LoadingSpinner,LoadingOverlay ,SmallButtonForReject,colorButtonStyle} from '../../styles/common.js';
import { getAllProInfoByAuthFlg,updateUserFromApproval } from "../../api/project";
import Modal from 'react-modal';


function ApprovaledlList(props) {

    const threespace='　　　';
    const onespace='　';
    const navigate = useNavigate();
    // ログイン状態ではない場合、ログイン画面へ遷移する
    
    const [tasks, setTasks] = useState([]);
    const [modalIsOpenForReject, setModalIsOpenForReject] = useState(false);
    const [currentTask, setCurrentTask] = useState(Object);
    const [reloadEffect, setReloadEffect] = useState(false); // useEffectの再実行を制御するための状態
    const [loading, setLoading] = useState(false);
    // ユーザ種別（１：プロ、２：ゲスト(その他)）
    
    useEffect(() => {
        // このエフェクトは reloadEffect が変更されるたびに再実行されます
        var userId = sessionStorage.getItem('userId');
        if (userId === undefined || userId === null || userId === "") {
            navigate('/', {});
        }
        // 雀活マスター承認済
        getAllProInfoByAuthFlg('1').then(res => {
            setTasks(res.data);
        }).catch(res => {
        })
    }, [reloadEffect, navigate]); // reloadEffect を依存配列に追加

    // 否認
    const handleApproveForReject = async () => {
        // ここに承認処理を追加
        closeModalForReject();
        setLoading(true);
        await updateTaskForReject(currentTask);
        setLoading(false);
        resetState();
    };

    const updateTaskForReject = async (task) => {
        var jUser = {};
        jUser.userId=task.user_id;
        jUser.email=task.email;
        jUser.userName=task.user_name;
        jUser.userFlg=selectedUserFlg[task.user_id];
        // 否認
        jUser.authFlg='3';
        updateUserFromApproval(jUser).then(res => {
            if (res.code === 200) {
                navigate('/ApprovalList', {});
            }
        }).catch(res => {
        })
        // ダミーの遅延
        await new Promise(resolve => setTimeout(resolve, 1000));
    };

    const openModalForReject = (task) => {
      console.log(task);
      setCurrentTask(task);
      setModalIsOpenForReject(true);
    };

    const closeModalForReject = () => {
    setModalIsOpenForReject(false);
    };

    const resetState = () => {
      setCurrentTask(Object);
      setReloadEffect(prev => !prev); // reloadEffectをトグルすることでuseEffectを再実行
      // 他の状態をリセットする場合はここに追加
    };

    const TwitterIcon = () => {
        return <FaTwitter />;
    }

    const [selectedUserFlg] = useState({});
    return (
        <MainLayout>
            <ApprovaledlListLayout>
                <ApprovaledlListBody>
                    <GREENTitle>{FW.F_W_HOME_ApprovaledList}</GREENTitle>
                            <ApprovaledlListRowOfHead >Twitter名前／ID</ApprovaledlListRowOfHead>
                            <ApprovaledlListRowOfHead >{threespace}雀活マスター表示名前</ApprovaledlListRowOfHead>
                            <ApprovaledlListRowOfHead >{threespace}{threespace}E-Mail</ApprovaledlListRowOfHead>
                            <ApprovaledlListRowOfHead >{threespace}{threespace}プロ／ゲスト</ApprovaledlListRowOfHead>
                            {loading && (
                                <LoadingOverlay>
                                <LoadingSpinner />
                                </LoadingOverlay>
                             )}
                            {tasks.map((item) => {
                            return (
                                <ApprovaledlListBody key={item.user_id}>
                                    <ApprovaledlListRow><TwitterIcon />{item.twitter_name}／
                                    <a href={`https://x.com/${item.user_id}`} target="_blank" rel="noopener noreferrer">{item.user_id} </a>
                                    </ApprovaledlListRow>
                                    <ApprovaledlListRow>{threespace}{item.user_name}</ApprovaledlListRow>
                                    <ApprovaledlListRow>{threespace}{threespace}{item.email}</ApprovaledlListRow>
                                    <ApprovaledlListRow>
                                    {onespace}<input type="radio" name={`userFlg_${item.user_id}`} value="1" defaultChecked={true} 
                                        checked={item.user_flg === '1'}
                                        />プロ
                                        {onespace}<input type="radio" name={`userFlg_${item.user_id}`}  value="2" 
                                        checked={item.user_flg === '2'}/>ゲスト
                                        {onespace}<input type="radio" name={`userFlg_${item.user_id}`}  value="3" 
                                        checked={item.user_flg === '3'}/>雀荘
                                        {onespace}<input type="radio" name={`userFlg_${item.user_id}`}  value="4" 
                                        checked={item.user_flg === '4'}/>麻雀BAR
                                        <SmallButtonForReject onClick={() => openModalForReject(item)}>解除</SmallButtonForReject></ApprovaledlListRow>
                                            <ApprovaledlListRowKu></ApprovaledlListRowKu>
                                </ApprovaledlListBody>
                            );
                            })} 
                </ApprovaledlListBody>
            </ApprovaledlListLayout>
            <Modal
                isOpen={modalIsOpenForReject}
                onRequestClose={closeModalForReject}
                contentLabel="雀活マスター否認">
                <div>
                    <p>◾️申込者：<br></br> ・{currentTask.twitter_name}<br></br>・{currentTask.user_name}<br></br>
                    ・{currentTask.email}<br></br>・{currentTask.user_flg==="1"?"プロ":
                    (currentTask.user_flg==="2"?"ゲスト":
                        (currentTask.user_flg==="3"?"雀荘":
                            (currentTask.user_flg==="4"?"麻雀BAR":""))
                    )}
                    <br></br><br></br>利用権限を解除した後、すでに連携済のスケジュール、お気に入りデータを削除します。<br></br><br></br>雀活マスターアプリの利用権限を解除しますか？</p>
                </div>
                <ButtonContainer>
                    <button onClick={closeModalForReject}>いいえ</button><button style={colorButtonStyle(RED)} onClick={handleApproveForReject} >解除</button>
                </ButtonContainer>
            </Modal>
        </MainLayout>
    );
}

export default ApprovaledlList;