import React, { useEffect,useState } from 'react';
import { FaTwitter } from 'react-icons/fa';
import { ApprovalListLayout, ApprovalListBody, Title, ApprovalListRow, ApprovalListRowKu,
    ApprovalListRowOfHead,ButtonContainer } from './ApprovalList.style.js';
import { LIGHT_BLUE,RED} from '../../styles/color';
import FW from '../../config/word.json';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../components/Layouts/Main/MainLayout.index.js';
import { SmallButton,LoadingSpinner,LoadingOverlay,colorButtonStyle,smalButtonStyle} from '../../styles/common.js';
import { getAllProInfoByAuthFlg,updateUserFromApproval } from "../../api/project";
import Modal from 'react-modal';


function ApprovalList(props) {

    const threespace='　　　';
    const onespace='　';
    const navigate = useNavigate();
    // ログイン状態ではない場合、ログイン画面へ遷移する
    
    const [tasks, setTasks] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsOpenForReject, setModalIsOpenForReject] = useState(false);
    const [currentTask, setCurrentTask] = useState(Object);
    const [reloadEffect, setReloadEffect] = useState(false); // useEffectの再実行を制御するための状態
    const [loading, setLoading] = useState(false);
    // ユーザ種別（１：プロ、２：ゲスト(その他)）
    
    useEffect(() => {
        // このエフェクトは reloadEffect が変更されるたびに再実行されます
        var userId = sessionStorage.getItem('userId');
        if (userId === undefined || userId === null || userId === "") {
            navigate('/', {});
        }
        // 雀活マスター承認待ち
        getAllProInfoByAuthFlg('2').then(res => {
            setTasks(res.data);
        }).catch(res => {
        })
    }, [reloadEffect, navigate]); // reloadEffect を依存配列に追加

    const updateTask = async (task) => {
        var jUser = {};
        jUser.userId=task.user_id;
        jUser.email=task.email;
        jUser.userName=task.user_name;
        jUser.userFlg=selectedUserFlg[task.user_id];
        // 承認
        jUser.authFlg='1';
        updateUserFromApproval(jUser).then(res => {
            if (res.code === 200) {
                navigate('/ApprovalList', {});
            }
        }).catch(res => {
        })
        // ダミーの遅延
        await new Promise(resolve => setTimeout(resolve, 1000));
      };

      const updateTaskForReject = async (task) => {
        var jUser = {};
        jUser.userId=task.user_id;
        jUser.email=task.email;
        jUser.userName=task.user_name;
        jUser.userFlg=selectedUserFlg[task.user_id];
        // 否認
        jUser.authFlg='3';
        updateUserFromApproval(jUser).then(res => {
            if (res.code === 200) {
                navigate('/ApprovalList', {});
            }
        }).catch(res => {
        })
        // ダミーの遅延
        await new Promise(resolve => setTimeout(resolve, 1000));
      };
      
      const openModal = (task) => {
        console.log(task);
        setCurrentTask(task);
        setModalIsOpen(true);
      };
    
      const closeModal = () => {
        setModalIsOpen(false);
      };


      const openModalForReject = (task) => {
        console.log(task);
        setCurrentTask(task);
        setModalIsOpenForReject(true);
      };
    
      const closeModalForReject = () => {
        setModalIsOpenForReject(false);
      };

      const resetState = () => {
        setCurrentTask(Object);
        setReloadEffect(prev => !prev); // reloadEffectをトグルすることでuseEffectを再実行
        // 他の状態をリセットする場合はここに追加
      };
      // 承認
      const handleApprove = async () => {
        // ここに承認処理を追加
        closeModal();
        setLoading(true);
        await updateTask(currentTask);
        setLoading(false);
        resetState();
      };

      // 否認
      const handleApproveForReject = async () => {
        // ここに承認処理を追加
        closeModalForReject();
        setLoading(true);
        await updateTaskForReject(currentTask);
        setLoading(false);
        resetState();
      };

    const TwitterIcon = () => {
        return <FaTwitter />;
    }

    const [selectedUserFlg, setSelectedUserFlg] = useState({});

    const handleGenderChange = (proId, value) => {
        setSelectedUserFlg(prevUserFlg => ({
            ...prevUserFlg,
            [proId]: value
        }));
    };

    return (
        <MainLayout>
            <ApprovalListLayout>
                <ApprovalListBody>
                    <Title>{FW.F_W_HOME_ApprovalList}</Title>
                            <ApprovalListRowOfHead >Twitter名前／ID</ApprovalListRowOfHead>
                            <ApprovalListRowOfHead >{threespace}雀活マスター表示名前</ApprovalListRowOfHead>
                            <ApprovalListRowOfHead >{threespace}{threespace}E-Mail</ApprovalListRowOfHead>
                            <ApprovalListRowOfHead >{threespace}プロ／ゲスト{threespace}{threespace}処理</ApprovalListRowOfHead>
                            {loading && (
                                <LoadingOverlay>
                                <LoadingSpinner />
                                </LoadingOverlay>
                             )}
                            {tasks.map((item) => {
                            return (
                                <ApprovalListBody key={item.user_id}>
                                    <ApprovalListRow><TwitterIcon />{item.twitter_name}／
                                    <a href={`https://x.com/${item.user_id}`} target="_blank" rel="noopener noreferrer">{item.user_id} </a>
                                    </ApprovalListRow>
                                    <ApprovalListRow>{threespace}{item.user_name}</ApprovalListRow>
                                    <ApprovalListRow>{threespace}{threespace}{item.email}</ApprovalListRow>
                                    <ApprovalListRow>
                                        <input type="radio" name={`userFlg_${item.user_id}`} value="1" defaultChecked={true} 
                                        checked={selectedUserFlg[item.user_id] === '1'}
                                        onChange={(e) => handleGenderChange(item.user_id, e.target.value)}
                                        />プロ
                                        <input type="radio" name={`userFlg_${item.user_id}`}  value="2" 
                                        checked={selectedUserFlg[item.user_id] === '2'}
                                        onChange={(e) => handleGenderChange(item.user_id, e.target.value)} />ゲスト
                                        <input type="radio" name={`userFlg_${item.user_id}`}  value="3" 
                                        checked={selectedUserFlg[item.user_id] === '3'}
                                        onChange={(e) => handleGenderChange(item.user_id, e.target.value)} />雀荘
                                        <input type="radio" name={`userFlg_${item.user_id}`}  value="4" 
                                        checked={selectedUserFlg[item.user_id] === '4'}
                                        onChange={(e) => handleGenderChange(item.user_id, e.target.value)} />麻雀BAR
                                        <SmallButton style={smalButtonStyle(RED)}  onClick={() => openModalForReject(item)}>否認</SmallButton>
                                        <SmallButton onClick={() => openModal(item)}>承認</SmallButton>
                                        </ApprovalListRow>
                                            <ApprovalListRowKu></ApprovalListRowKu>
                                </ApprovalListBody>
                            );
                            })} 
                </ApprovalListBody>
            </ApprovalListLayout>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="雀活マスター承認">
                <div>
                    <p>◾️申込者：<br></br> ・{currentTask.twitter_name}<br></br>・{currentTask.user_name}<br></br>
                    ・{currentTask.email}<br></br>・{selectedUserFlg[currentTask.user_id]==="1"?"プロ":
                    (selectedUserFlg[currentTask.user_id]==="2"?"ゲスト":
                        (selectedUserFlg[currentTask.user_id]==="3"?"雀荘":
                            (selectedUserFlg[currentTask.user_id]==="4"?"麻雀BAR":""))
                    )}
                    <br></br><br></br>雀活マスターアプリの利用権限を付与します</p>
                </div>
                <ButtonContainer>
                    <button onClick={closeModal}>いいえ</button><button style={colorButtonStyle(LIGHT_BLUE)} onClick={handleApprove} >承認</button>
                </ButtonContainer>
            </Modal>
            <Modal
                isOpen={modalIsOpenForReject}
                onRequestClose={closeModalForReject}
                contentLabel="雀活マスター否認">
                <div>
                    <p>◾️申込者：<br></br> ・{currentTask.twitter_name}<br></br>・{currentTask.user_name}<br></br>
                    ・{currentTask.email}<br></br>・{selectedUserFlg[currentTask.user_id]==="1"?"プロ":
                    (selectedUserFlg[currentTask.user_id]==="2"?"ゲスト":
                        (selectedUserFlg[currentTask.user_id]==="3"?"雀荘":
                            (selectedUserFlg[currentTask.user_id]==="4"?"麻雀BAR":""))
                    )}
                    <br></br><br></br>雀活マスターアプリの利用権限を拒否します</p>
                </div>
                <ButtonContainer>
                    <button onClick={closeModalForReject}>いいえ</button><button style={colorButtonStyle(RED)} onClick={handleApproveForReject} >拒否</button>
                </ButtonContainer>
            </Modal>
        </MainLayout>
    );
}

export default ApprovalList;